import PropTypes from 'prop-types';
import ShoppableRecipe from './ShoppableRecipe';
import { s3BucketUrls } from '../../../config/constants';

function RecipeImage({ imageName, recipeName, isShoppable }) {
  return (
    <div className="relative w-full h-screen-65 md:h-screen">
      <img
        className="absolute w-full h-full object-cover"
        src={s3BucketUrls.recipe + imageName}
        alt={recipeName}
      />
      {isShoppable && <ShoppableRecipe />}
    </div>
  );
}

RecipeImage.propTypes = {
  imageName: PropTypes.string.isRequired,
  recipeName: PropTypes.string.isRequired,
  isShoppable: PropTypes.bool.isRequired,
};

export default RecipeImage;
