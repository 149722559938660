import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { s3BucketUrls } from '../../../config/constants';

function RecipeCard({ id, imageName, recipeName }) {
  return (
    <span key={id}>
      <div className="border bg-gray-400 ml-5 h-150px w-180px">
        <Link to={`/${id}`}>
          <img
            className="h-full w-full object-cover rounded-lg"
            src={s3BucketUrls.recipe + imageName}
            alt={recipeName}
          />
        </Link>
      </div>
    </span>
  );
}

RecipeCard.propTypes = {
  id: PropTypes.number.isRequired,
  imageName: PropTypes.string.isRequired,
  recipeName: PropTypes.string.isRequired,
};

export default RecipeCard;
