import { imageAssets } from '../../../config/constants';

function ShoppableRecipe() {
  return (
    <div className="absolute bottom-0 right-0 mr-4 mb-4 bg-gradient-to-l from-brand-white to-brand-red px-2 py-1 rounded text-white">
      <div className="flex flex-row items-center">
        <img src={imageAssets.icon.shop} alt="shop-cart" className="h-3 w-3" />
        <span className="ml-2">Shoppable Recipe</span>
      </div>
    </div>
  );
}

export default ShoppableRecipe;
