import { getSectionRecipes } from '../../../api/recipe';
import useApi from '../../../hooks/useApi';
import RecipeCard from './RecipeCard';

function RecipeRecommendation() {
  const { isLoading, data } = useApi(getSectionRecipes, { section_id: 3, veg_only: 0 });

  const renderRecipes = () => {
    if (data && data.recipes.length > 0) {
      return data.recipes.map((recipe) => (
        <RecipeCard
          key={recipe.id}
          id={recipe.id}
          imageName={recipe.image_name}
          recipeName={recipe.recipename}
        />
      ));
    }

    return null;
  };

  return (
    <div className="mt-12">
      <div className="text-center font-black text-lg">
        Recipes you can find in the app
        <span role="img" aria-label="point-emoji">
          👇
        </span>
      </div>
      <div className="flex h-160px mt-5 overflow-scroll overflow-y-hidden">
        {isLoading ? (
          <span>
            <div className="animate-pulse bg-gray-400 border ml-5 rounded h-150px w-180px" />
          </span>
        ) : (
          renderRecipes()
        )}
      </div>
      <div className="text-center px-5 pb-5 mt-5">
        {'15 Cuisines, One Pot Meals, < 30 Mins Recipes, No Bake Desserts & More!'}
      </div>
    </div>
  );
}

export default RecipeRecommendation;
