import PropTypes from 'prop-types';

function Servings({ onUseApp, link }) {
  return (
    <div className="bg-brand-coral w-full p-4 flex items-center justify-center flex-col">
      <div className="text-center">
        <span className="text-white text-base tracking-wide">Looking to change servings ?</span>
      </div>
      {link && link !== '' && (
        <button
          onClick={onUseApp}
          type="button"
          className="mt-5 bg-white focus:outline-none py-1 px-2 rounded mx-auto text-brand-coral font-black text-base"
        >
          Open Recipe In App
        </button>
      )}
    </div>
  );
}

Servings.propTypes = {
  onUseApp: PropTypes.func.isRequired,
  link: PropTypes.string,
};

Servings.defaultProps = {
  link: null,
};

export default Servings;
