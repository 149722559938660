import PropTypes from 'prop-types';

export const RecipePropTypes = {
  id: PropTypes.number.isRequired,
  recipename: PropTypes.string.isRequired,
  image_name: PropTypes.string.isRequired,
};

export const PublisherPropTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const IngredientPropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  pivot: PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    serving: PropTypes.string,
  }),
};
