import { useEffect, useState } from 'react';

export default function useApi(api, params, hooksDependency = []) {
  const [data, setData] = useState({ isLoading: false, data: null });

  useEffect(() => {
    setData({ isLoading: true, data: null });

    api(params)
      .then((response) => {
        setData({ isLoading: false, data: response.data });
      })
      .catch(() => {
        setData({ isLoading: false, data: null });
      });
  }, hooksDependency);

  return data;
}
