import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import branch from 'branch-sdk';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { RecipePropTypes } from '../../config/definedPropTypes';
import RecipeImage from './components/RecipeImage';
import FloatingTop from './components/FloatingTop';
import Details from './components/Details';
import Ingredients from './components/Ingredients';
import Servings from './components/Servings';
import Steps from './components/Steps';
import RecipeRecommendation from './components/RecipeRecommendation';
import Footer from './components/Footer';
import { s3BucketUrls, APP_URL, deeplinkTypes } from '../../config/constants';

function Recipe({ data }) {
  const [link, setLink] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLink(null);

    const linkData = {
      data: {
        recipeId: data.id,
        type: deeplinkTypes.RECIPE,
        $og_title: data.recipename,
        $og_description: data.description,
        $og_image_url: s3BucketUrls.recipe + data.image_name,
      },
    };

    branch.link(linkData, (err, branchLink) => {
      setLink(branchLink);
    });
  }, [data.id]);

  const onUseApp = () => {
    window.location.href = link;
  };

  return (
    <div className="grid md:grid-cols-3">
      <Helmet>
        <title>{data.recipename}</title>
        <meta property="title" content={data.recipename} />
        <meta property="image" content={s3BucketUrls.recipe + data.image_name} />
        <meta property="description" content={data.description} />
        <link rel="canonical" href={APP_URL + location.pathname} />
      </Helmet>
      <FloatingTop onUseApp={onUseApp} link={link} />
      <div className="md:col-span-1 float-left">
        <RecipeImage
          imageName={data.image_name}
          recipeName={data.recipename}
          isShoppable={data.grocery_url !== null}
        />
      </div>
      <div className="md:col-span-2 md:h-screen overflow-scroll overflow-x-hidden">
        <Details
          recipeName={data.recipename}
          level={data.level_of_difficulty || data.difficulty_level}
          publisher={data.publisher}
          totalTime={data.total_time || 0}
          prepTime={data.preptime || 0}
          cookingTime={data.cookingtime || 0}
          description={data.description || ''}
        />
        <Ingredients ingredients={data.ingredients} />
        <Servings onUseApp={onUseApp} link={link} />
        <Steps steps={data.instructions} onUseApp={onUseApp} link={link} />
        <RecipeRecommendation />
        <Footer />
      </div>
    </div>
  );
}

Recipe.propTypes = {
  data: PropTypes.shape(RecipePropTypes).isRequired,
};

export default Recipe;
