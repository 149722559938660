/* eslint-disable import/prefer-default-export */
import { ELOQUENT } from '../config/constants';

/**
 * Get one recipe
 * @param {Number} id
 * @returns {Promise}
 */
export const getOneRecipe = (id) => ELOQUENT.Recipe.getOne(id);

/**
 * Get dynamic section
 * @param {Object} params
 * @returns {Promise}
 */
export const getSectionRecipes = (params) => ELOQUENT.Recipe.getSectionRecipes(params);
