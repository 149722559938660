import { useParams } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOneRecipe } from '../../api/recipe';
import useApi from '../../hooks/useApi';
import Recipe from '../../screens/Recipe/Recipe';

function RecipeContainer() {
  const { id } = useParams();
  const { isLoading, data } = useApi(getOneRecipe, id, [id]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen mx-auto">
        <FontAwesomeIcon className="fa-spin fa-6x text-brand-coral" icon={faSpinner} />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return <Recipe data={data} />;
}

export default RecipeContainer;
