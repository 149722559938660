import PropTypes from 'prop-types';
import { IngredientPropTypes } from '../../../config/definedPropTypes';

function Ingredients({ ingredients }) {
  return (
    <div className="w-full p-4">
      <div className="flex flex-row items-center">
        <strong className="text-lg font-black">Ingredients</strong>
        <span className="italic ml-5 text-sm">3 servings</span>
      </div>
      {ingredients.map((ingredient) => (
        <div className="ml-2 mt-2" key={ingredient.id}>
          <span className="text-base">
            {ingredient.pivot.quantity}
            {` ${ingredient.pivot.serving} `}
            {ingredient.name}
          </span>
        </div>
      ))}
    </div>
  );
}

Ingredients.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.shape(IngredientPropTypes)).isRequired,
};

export default Ingredients;
