import PlayStoreBadge from '../assets/google-play-badge.png';
import AppStoreBadge from '../assets/app-store-badge.png';
import YoRipeLogo from '../assets/yoripe-logo.png';
import ShopIcon from '../assets/shop.png';

export const ELOQUENT = window.Eloquent;

export const APP_ENV = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_URL;

const s3Bucket = APP_ENV === 'production' ? 'yoripe-s3-production' : 'yoripe-staging';
const s3BucketBaseUrl = `https://s3-ap-southeast-1.amazonaws.com/${s3Bucket}/`;

export const s3BucketUrls = {
  recipe: `${s3BucketBaseUrl}recipe/`,
  publishers: `${s3BucketBaseUrl}publishers/`,
};

export const levels = {
  1: 'Easy',
  2: 'Medium',
  3: 'Hard',
};

export const imageAssets = {
  logo: {
    yoripe: YoRipeLogo,
  },
  icon: {
    shop: ShopIcon,
  },
};

export const storeDetails = {
  appStore: {
    url: 'https://apps.apple.com/sg/app/yoripe-tasty-easy-recipes/id1344175332',
    badge: AppStoreBadge,
  },
  playStore: {
    url: 'https://play.google.com/store/apps/details?id=com.yoripeapp&hl=en_SG',
    badge: PlayStoreBadge,
  },
};

/**
 * Deeplinking types, copied from yoripe-app repository
 */
export const deeplinkTypes = {
  RECIPE: 'recipe',
};
