/* eslint-disable react/destructuring-assignment */
/**
 * Disabled react/destructuring-assignment since component has a lot of props
 * and much cleaner to read if props is not destructured
 */
import PropTypes from 'prop-types';
import { levels, s3BucketUrls } from '../../../config/constants';
import { PublisherPropTypes } from '../../../config/definedPropTypes';

function Details(props) {
  return (
    <div className="w-full p-4 md:pt-16">
      <div className="flex flex-row">
        <strong className="flex-1 text-lg font-black pr-4">{props.recipeName}</strong>
        <span className="flex flex-row text-sm">
          {levels[props.level]}&nbsp;
          <span role="img" aria-label="swear-emoji">
            🤞
          </span>
        </span>
      </div>
      <div className="flex flex-row items-center mt-5">
        <img src={s3BucketUrls.publishers + props.publisher.image} alt={props.publisher.name} />
        <span className="text-base ml-2">{props.publisher.name}</span>
      </div>
      {props.totalTime > 0 && (
        <div className="flex flex-row flex-wrap mt-5">
          <span className="text-base">{props.totalTime} mins</span>
          {props.prepTime > 0 && (
            <>
              <span className="ml-2 text-base text-gray-500">{props.prepTime} mins prep</span>
              <span className="ml-2 text-base text-gray-500">·</span>
            </>
          )}
          {props.cookingTime > 0 && (
            <span className="ml-2 text-base text-gray-500">{props.cookingTime} mins cook</span>
          )}
        </div>
      )}
      <div className="mt-5 text-base italic">{props.description}</div>
    </div>
  );
}

Details.propTypes = {
  recipeName: PropTypes.string.isRequired,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  publisher: PropTypes.shape(PublisherPropTypes).isRequired,
  totalTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  prepTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cookingTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
};

export default Details;
