import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import branch from 'branch-sdk';
import RecipeContainer from '../Recipe/RecipeContainer';
import { API_URL } from '../../config/constants';

/* eslint-disable no-underscore-dangle */
window._axios.defaults.baseDomain = API_URL;
window._axios.defaults.baseURL = API_URL;
/* eslint-enable no-underscore-dangle */

branch.init('key_live_ckIzmlzYoK1Qnm0tc2BvkkgoByog1Fi3');

function AppContainer() {
  return (
    <Router>
      <Switch>
        <Route path="/:id">
          <RecipeContainer />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppContainer;
