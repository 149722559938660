import { storeDetails } from '../../../config/constants';

function Footer() {
  return (
    <div className="w-full bg-brand-green p-4">
      <div className="text-center text-white text-lg font-black tracking-wide">
        <span>Don’t miss out.</span>
        <br />
        <span>Join 30,000+ Home Cooks Using YoRipe</span>
      </div>
      <div className="flex flex-row items-center justify-center mt-5">
        <div className="mr-2" style={{ height: '58px', width: '150px' }}>
          <a href={storeDetails.appStore.url}>
            <img src={storeDetails.appStore.badge} alt="App Store" />
          </a>
        </div>
        <div className="ml-2" style={{ height: '58px', width: '150px' }}>
          <a href={storeDetails.playStore.url}>
            <img src={storeDetails.playStore.badge} alt="Google Play Store" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
