import PropTypes from 'prop-types';
import { useMemo } from 'react';

function Steps({ steps, onUseApp, link }) {
  const slicedSteps = useMemo(() => {
    const parsedSteps = JSON.parse(steps);

    /** Show only 80% of steps */
    return parsedSteps.slice(0, Math.floor(parsedSteps.length * 0.8));
  }, [steps]);

  return (
    <div className="w-full px-4 mt-5">
      <strong className="text-lg font-black">Steps</strong>
      <div className="relative steps-container">
        {slicedSteps.map((step, index) => (
          <div key={step} className="mt-5 ml-2">
            {index + 1}. {step}
          </div>
        ))}
      </div>
      <hr className="my-5" />
      <div className="flex flex-col items-center">
        <div className="font-black text-lg">Get the full steps in app.</div>
        <div className="mt-5 text-center text-lg">
          Choose convenience: Plan & Shop in 1 Minute Browse 2000+ Curated Asian Recipes Make
          Grocery List & Adjust Quantity
        </div>
        {link && link !== '' && (
          <button
            onClick={onUseApp}
            type="button"
            className="mt-5 text-white py-1 px-5 rounded mx-auto bg-brand-coral"
          >
            Use App
          </button>
        )}
      </div>
    </div>
  );
}

Steps.propTypes = {
  steps: PropTypes.string.isRequired,
  onUseApp: PropTypes.func.isRequired,
  link: PropTypes.string,
};

Steps.defaultProps = {
  link: null,
};

export default Steps;
