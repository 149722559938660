import PropTypes from 'prop-types';
import { imageAssets } from '../../../config/constants';

function FloatingTop({ onUseApp, link }) {
  return (
    <div className="flex flex-row justify-between items-center fixed z-10 h-12 w-full bg-brand-green px-6">
      <div className="w-90px h-30px">
        <img src={imageAssets.logo.yoripe} alt="yoripe-logo" className="object-contain" />
      </div>
      {link && link !== '' && (
        <button
          onClick={onUseApp}
          type="button"
          className="bg-white focus:outline-none py-1 px-2 rounded text-brand-green text-sm font-black"
        >
          Use App
        </button>
      )}
    </div>
  );
}

FloatingTop.propTypes = {
  onUseApp: PropTypes.func.isRequired,
  link: PropTypes.string,
};

FloatingTop.defaultProps = {
  link: null,
};

export default FloatingTop;
